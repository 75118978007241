import { Box, Flex, Text } from '@stars-ecom/shared-atoms-ui'
import { AccountLayout, AccountSubscription, WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const AccountLoyaltyCardPage = () => {
  const websiteContext = useContext(WebsiteContext)
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="15px"
      backgroundColor="#f6f3f1"
      w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Mes abonnements - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <title>{`Mes abonnements - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AccountLayout>
        <AccountSubscription />
      </AccountLayout>
      <Flex
        maxWidth={{ base: '100%', md: '1000px' }}
        padding={{ base: '0 15px', md: '0 0 0 235px' }}
        direction="column">
        <Box
          mt="20px"
          mb="10px"
          fontSize="11px"
          ml={{ md: '20px' }}
          fontFamily="PT Sans Narrow"
          color="#5e5e5e"
          align="justify"
          sx={{
            '& a': {
              color: websiteContext?.mainColor,
              '&:hover': {
                textDecoration: 'underline',
                color: websiteContext?.darkColor
              }
            }
          }}
          dangerouslySetInnerHTML={{ __html: websiteContext?.legalMention }}
        />
        <Text
          mt="10px"
          mb="10px"
          fontSize="11px"
          ml={{ md: '20px' }}
          fontFamily="PT Sans Narrow"
          color="#5e5e5e"
          align="justify">
          Lorsque vous cliquez sur la case « Enregistrer », vous consentez à ce que vos données de
          carte bancaire soient conservées pour faciliter vos futurs achats, et ne pas ressaisir
          votre numéro de carte bancaire lors de vos prochaines commandes. Les coordonnées de votre
          bancaire sont transmises et conservées de manière sécurisée par notre partenaire de
          confiance HiPay sur des serveurs informatiques situés en EUROPE. Vous pouvez modifier ou
          supprimer les cartes bancaires enregistrées en vous connectant à votre Espace Client,
          rubrique « Mes Cartes Bancaires ».
        </Text>
      </Flex>
    </Flex>
  )
}

AccountLoyaltyCardPage.pageType = 'account'

export default AccountLoyaltyCardPage
